body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /*background-color: rgba(199, 138, 102, 0.25); /* Light brown extracted from the background */
  /*background:linear-gradient(135deg, rgba(214, 125, 70, 0.8), rgba(58, 142, 141, 0.8)); /* Orange to Teal gradient with opacity */
  /*background-blend-mode: multiply; /* To darken the gradient when overlapped */
}

/* Scroll Icon Styling */
.scroll-icon {
  position: absolute;
  top: 550px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  color: black; /* Orange from the fire effect */
  cursor: pointer;
  animation: bounce 1s infinite;
  z-index: 1000;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}


